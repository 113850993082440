'use client';

import {
    Button,
    Column,
    Cover,
    Flex,
    Heading,
    Icon,
    RichText,
    Row,
} from 'oddsgate-ds';
import React, { Suspense } from 'react';

import DisplayImage from '@/components/common/DisplayImage/DisplayImage';
import Link from 'next/link';
import NavigationEvents from '@/components/common/NavigationEvents/NavigationEvents';
import { useGlobalContext } from '@/lib/globalContext';

export default function NotFound() {
    const state = useGlobalContext();

    return (
        <>
            <Suspense>
                <NavigationEvents
                    acf={{ page_style: 'dark', header_absolute: true }}
                />
            </Suspense>
            <div>
                <Row align="center" className="pt-10 pb-10">
                    <Column md={1}></Column>
                    <Column md={6}>
                        <div>
                            <Heading
                                tag="h2"
                                size="h1"
                                className="color-primary50 text-uppercase">
                                {
                                    (
                                        state?.dict?.page404 as Record<
                                            string,
                                            string
                                        >
                                    )?.preTitle
                                }
                            </Heading>
                            <Heading
                                tag="h1"
                                size="h1"
                                className="color-secondary50 mt-3">
                                {
                                    (
                                        state?.dict?.page404 as Record<
                                            string,
                                            string
                                        >
                                    )?.title
                                }
                            </Heading>
                            <Heading tag="span" size="h5" className="mt-4">
                                {
                                    (
                                        state?.dict?.page404 as Record<
                                            string,
                                            string
                                        >
                                    )?.text
                                }
                            </Heading>
                            <Link href={`/${state.lang}`}>
                                <Button
                                    variant="secondary"
                                    rightIcon={
                                        <Icon icon="icon-forward-arrow" />
                                    }
                                    className="mt-5">
                                    {
                                        (
                                            state?.dict?.page404 as Record<
                                                string,
                                                string
                                            >
                                        )?.button
                                    }
                                </Button>
                            </Link>
                        </div>
                    </Column>
                    <Column md={4}>
                        <DisplayImage
                            alt={'Page 404'}
                            url={'/assets/components/404.svg'}
                            width={445}
                            height={775}
                            fill={false}
                        />
                    </Column>
                    <Column md={1}></Column>
                </Row>
            </div>
        </>
    );
}
